import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';

class SuccessPage extends React.Component {

  componentDidMount() {

    const Data = useStaticQuery(graphql`
    query {
      allContentfulForm {
        nodes {
          redirectUrlOnSuccess
        }
      }

    }
    `);

    const formData = Data.allContentfulForm.nodes[0];

    window.location.href= `${formData.redirectUrlOnSuccess}/`;
  }

  render() {
    return (
      <>
        
      </>
    );
  }

}

export default SuccessPage;
